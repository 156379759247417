import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import { MenuController } from '@ionic/angular'

import { SharedService } from '../../services/shared.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  home = 'reservations'
  mobile = false

  @Input() title: string
  constructor(private router: Router,
    private menuCtrl: MenuController,
    private sharedService: SharedService) {
    this.mobile = sharedService.verifyMobile()
  }

  ngOnInit() { }

  validateHome() {
    this.mobile = this.sharedService.verifyMobile()
    return '/' + this.home === this.router.url
  }

  toogleMenu() {
    this.menuCtrl.toggle()
  }
}
