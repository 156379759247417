import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IonicModule } from '@ionic/angular'

import { NgxDatatableModule } from '@swimlane/ngx-datatable'

import { HeaderComponent } from './header/header.component'
import { CustomDatatableComponent } from './custom-datatable/custom-datatable.component'
import {CropperComponent} from './cropper/cropper.component'
import {ImageCropperModule} from 'ngx-image-cropper'
import {MatIconModule} from '@angular/material'

@NgModule({
  declarations: [
    HeaderComponent,
    CustomDatatableComponent,
    CropperComponent,
  ],
  exports: [
    HeaderComponent,
    CustomDatatableComponent,
    CropperComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ImageCropperModule,
    NgxDatatableModule,
    MatIconModule
  ]
})
export class ComponentsModule { }
