import { Injectable } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { HttpClient } from '@angular/common/http'

import { Storage } from '@ionic/storage'

import { Const } from '../util/const'
import { SharedService } from './shared.service'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  isLogged = false

  user: any = {
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    token: null
  }

  headers = new Headers()

  constructor(
    private storage: Storage,
    private formB: FormBuilder,
    private http: HttpClient,
    private sharedService: SharedService) { }

  loadInitialLogin() {
    this.getUserSqlite()
  }

  insertToken(idUsuario, email, token, user) {
    this.isLogged = true
    this.storage.set('idUsuario', idUsuario)
    this.storage.set('email', email)
    this.storage.set('token', token)
    this.storage.set('user', user);
  }

  getUser() {
    return this.user
  }

  getSesion() {
    return {
      email: this.user.email,
      token: this.user.token,
      idfranquicia: this.user.idfranquicia
    }
  }

  getUserSqlite() {
    console.log("hola");
    
    this.storage.get('idUsuario').then(idUsuario => {
      this.storage.get('token').then(token => {
        this.storage.get('email').then(email => {
          const result = {
            idUsuario: idUsuario,
            email,
            token
          }
          if (result.idUsuario && result.token && result.email) {
            this.loginToken(result)
          }
        })
      })
    })
  }

  loginToken(user) {
    user.login = true
    user.email = user.email
    this.http.post(Const.serverURL + 'admin/validarUsuarioToken', user, Const.headers).subscribe((data: any) => {
      if (data.response === true) {
        this.isLogged = true
        this.user = data
        this.sharedService.go('reservations')
      } else {
        this.logOut()
      }
    }, error => {
      console.log(error)
    })
  }

  login(user) {
    return this.http.post(Const.serverURL + 'admin/login', user, Const.headers)
  }

  logOut() {
    this.isLogged = false
    this.storage.clear()
    this.user = {
      email: null,
      firstName: null,
      lastName: null,
      phone: null,
      token: null
    }
    this.sharedService.go('/login')
  }

  createFormLogin(userLoginModel) {
    return this.formB.group({
      [userLoginModel.EMAIL]: new FormControl('', Validators.compose([Validators.required,
      Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$')])),
      [userLoginModel.PASSWORD]: new FormControl('', Validators.compose(
        [Validators.required, Validators.minLength(8), Validators.maxLength(32)]))
    })
  }

  redirectIfNotLogin() {
    if (!this.isLogged) {
      this.sharedService.go('/login')
    }
  }
}
