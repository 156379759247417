import {Component, Inject, OnInit} from '@angular/core'
import {MatDialogRef} from '@angular/material'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {Dimensions, ImageCroppedEvent, ImageTransform} from 'ngx-image-cropper'


@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit {
  imageFile: any = ''
  croppedImage: any = ''
  canvasRotation = 0
  rotation = 0
  scale = 1
  limitZoomOut = 0.5
  limitZoomIn = 1.5
  showCropper = false
  containWithinAspectRatio = false
  transform: ImageTransform = {}

  constructor(public dialogRef: MatDialogRef<CropperComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {

  }

  fileChangeEvent(event: any): void {
    this.imageFile = event.target.files[0]
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64
  }

  imageLoaded() {
    this.showCropper = true
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {
  }

  rotateLeft() {
    this.canvasRotation--
    this.flipAfterRotate()
  }

  rotateRight() {
    this.canvasRotation++
    this.flipAfterRotate()
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH
    const flippedV = this.transform.flipV
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    }
  }

  saveImage() {
    this.dialogRef.close(this.croppedImage)
  }

  resetImage() {
    this.scale = 1
    this.rotation = 0
    this.canvasRotation = 0
    this.transform = {}
  }

  zoomOut() {
    if ( this.scale > this.limitZoomOut) {
      this.scale -= .1
      this.transform = {
        ...this.transform,
        scale: this.scale
      }
    }
  }

  zoomIn() {
    if ( this.scale < this.limitZoomIn) {
      this.scale += .1
      this.transform = {
        ...this.transform,
        scale: this.scale
      }
    }
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    }
  }

  imageChangedEvent() {
    console.log('ya')
  }
}
