import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { LoginGuard } from './services/loginGuard.service'


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule', canActivate: [LoginGuard] },
  { path: 'clients', loadChildren: './pages/clients/clients.module#ClientsPageModule' },
  { path: 'reports', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
  { path: 'roles', loadChildren: './pages/roles/roles.module#RolesPageModule' },
  { path: 'users', loadChildren: './pages/users/users.module#UsersPageModule' },
  { path: 'reservations', loadChildren: './pages/reservations/reservations.module#ReservationsPageModule' },
  { path: 'washes-available', loadChildren: './pages/washes-available/washes-available.module#WashesAvailablePageModule' },
  { path: 'services', loadChildren: './pages/services/services.module#ServicesPageModule' },
  { path: 'plans', loadChildren: './pages/plans/plans.module#PlansPageModule' },
  { path: 'subscriptions', loadChildren: './pages/subscriptions/subscriptions.module#SubscriptionsPageModule' },
  { path: 'products', loadChildren: './pages/products/products.module#ProductsPageModule' },
  { path: 'code', loadChildren: './pages/code/code.module#CodePageModule' },
  { path: 'corporativo' , loadChildren:'./pages/corporativo/corporativo.module#CorporativoPageModule'},
  { path: 'corporativo-servicios' , loadChildren:'./pages/corporativo-servicios/corporativo-servicios.module#CorporativoServiciosPageModule'},
  { path: 'washer' , loadChildren:'./pages/washer/washer.module#WasherPageModule'}


]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
