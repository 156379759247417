export class Const {
    // server pruebas
    // public static readonly serverURL: string = 'https://lavelopues.co/pruebas/lavelo-pues-backend-nuevo2/'
    // server produccion
    public static readonly serverURL: string = 'https://lavelopues.co/lavelo-pues-backend-nuevo2/'
    // server local
    // public static readonly serverURL: string = 'http://localhost/lavelopues-new-backend/'

    public static readonly headers: any = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }

    // LocalStorage
    public static readonly localStorage = {
        user: {
            token: 'UserToken',
            id: 'UserId'
        }
    }

    public static readonly messages: any = {
        messages: {
            title: {
                success: 'Operación Exitosa',
                warning: 'Advertencia',
                error: 'Error en la Operación'
            },
            message: {
                successCreate: 'Se han creado los datos correctamente',
                successDelete: 'Se han eliminado los datos correctamente',
                successUpdate: 'Se han actualizado los datos correctamente',
                warning: '',
                error: 'Se ha generado un error',
                notRecordsFound: 'No se encontraron registros'
            }
        }
    }
}
