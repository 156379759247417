import { Injectable } from '@angular/core'
import { AlertController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor( private alertController: AlertController) { }

  async alert(header= '', subHeader= '', message= '', buttons: any= ['OK'], inputs= []) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      inputs,
      buttons
    })

    await alert.present()
  }
}
