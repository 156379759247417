import { NgModule } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { ComponentsModule } from './components/components.module'

import { Facebook } from '@ionic-native/facebook/ngx'
import { IonicStorageModule } from '@ionic/storage'
import { SQLite } from '@ionic-native/sqlite/ngx'
import { LoginService } from './services/login.service'
import { HttpClientModule } from '@angular/common/http'
import { HttpModule } from '@angular/http'
import { HTTP } from '@ionic-native/http/ngx'
import { GooglePlus} from '@ionic-native/google-plus/ngx'
import { Keyboard } from '@ionic-native/keyboard/ngx'
import { LoginGuard } from './services/loginGuard.service'

// angular material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {MatInputModule} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatNativeDateModule} from '@angular/material'
import {CropperComponent} from './components/cropper/cropper.component'

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    CropperComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    HttpModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Facebook ,
    SQLite,
    HTTP,
    GooglePlus,
    LoginService,
    FormBuilder,
    Keyboard,
    LoginGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
