import { Component } from '@angular/core'
import { Platform, MenuController } from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'

import { AlertService } from './services/alert.service'
import { LoginService } from './services/login.service'
import { SharedService } from './services/shared.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  menuOpts = [
    {
      name: 'Reservas',
      icon: 'cart',
      dataFunction: 'reservations',
      function: 'redirectTo',
      superAdmin: false,
    },
    {
      name: 'Servicios',
      icon: 'list',
      dataFunction: 'services',
      function: 'redirectTo',
      superAdmin: false,
    },
    {
      name: 'Lavados disponibles',
      icon: 'cart',
      dataFunction: 'washes-available',
      function: 'redirectTo',
      superAdmin: false,
    },
    {
      name: 'Clientes',
      icon: 'people',
      dataFunction: 'clients',
      function: 'redirectTo',
      superAdmin: false,
    },
    {
      name: 'Codigos',
      icon: 'logo-usd',
      dataFunction: 'code',
      function: 'redirectTo',
      superAdmin: false,
    },{
      name: 'Corporativo',
      icon: 'contacts',
      dataFunction: 'corporativo',
      function: 'redirectTo',
      superAdmin: false,
    },{
      name: 'Corporativo Servicios',
      icon: 'contacts',
      dataFunction: 'corporativo-servicios',
      function: 'redirectTo',
      superAdmin: false,
    },{
      name: 'Washer',
      icon: 'contacts',
      dataFunction: 'washer',
      function: 'redirectTo',
      superAdmin: false,
    },
    
    // {
    //   name: 'Planes',
    //   icon: 'list',
    //   dataFunction: 'plans',
    //   function: 'redirectTo',
    //   superAdmin: true,
    // },
    // {
    //   name: 'Suscripciones',
    //   icon: 'list',
    //   dataFunction: 'subscriptions',
    //   function: 'redirectTo',
    //   superAdmin: true,
    // },
    // {
    //   name: 'Productos',
    //   icon: 'list',
    //   dataFunction: 'products',
    //   function: 'redirectTo',
    //   superAdmin: true,
    // },
    {
      name: 'Cerrar Sesión',
      icon: 'log-out',
      dataFunction: null,
      function: 'logout',
      superAdmin: false,
    },
  ]

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menuCtrl: MenuController,
    private alertService: AlertService,
    private loginService: LoginService,
    private sharedServiceService: SharedService
  ) {
    this.initializeApp()
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault()
      this.splashScreen.hide()
    })
  }

  isLogged() {
    return this.loginService.isLogged
  }

  functionMenu(action, dataAction) {
    if (action === 'redirectTo') {
      this.sharedServiceService.go(dataAction)
      this.menuCtrl.close()
    } else if (action === 'logout') {
      const buttons = [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Cerrar Sesión',
          handler: () => {
            this.loginService.logOut()
          }
        }
      ]

      this.alertService.alert('Cerrar Sesión', '', '¿Deseas cerrar tu sesión actual?', buttons)
    }
  }
}
