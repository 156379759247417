import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { IonContent } from '@ionic/angular'
import {Observable} from 'rxjs/internal/Observable'
import {CropperComponent} from '../components/cropper/cropper.component'
import {MatDialog} from '@angular/material'

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private router: Router,
              private dialog: MatDialog) { }

  go(url: string) {
    this.router.navigateByUrl(url)
  }

  scrollToElement(element: string, content: IonContent) {
    let y = document.getElementById(element).offsetTop
    content.scrollToPoint(0, y, 1000)
  }

  verifyMobile(): boolean {
    return window.screen.width <= 540
  }

  openCropperComponent(data: { [key: string]: any }): Observable<any> {
    const dialogRef = this.dialog.open(CropperComponent, {
      width: '90%',
      minHeight: '90vh',
      maxHeight: '95vh',
      data
    })
    return dialogRef.afterClosed()
  }
}
