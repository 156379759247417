import { Component, Input, ViewChild, OnInit } from '@angular/core'

import { CustomDatatable } from '../../interfaces/custom-datatable'

@Component({
  selector: 'app-custom-datatable',
  templateUrl: './custom-datatable.component.html',
  styleUrls: ['./custom-datatable.component.scss'],
})
export class CustomDatatableComponent implements OnInit {

  @ViewChild('table') table: any

  @Input() customDatatable: CustomDatatable
  rows = []
  columns = []
  filterColumns = []
  timeout: any

  constructor() { }

  ngOnInit() {
    this.rows = this.customDatatable.data
    this.columns = this.customDatatable.titles
    this.filterColumns = this.customDatatable.filterColumns
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase()
    let temp = this.customDatatable.data

    temp = temp.filter(d => {
      for (const key of this.filterColumns) {
        if (d[key].toLowerCase().indexOf(val) !== -1) {
          return true
        }
      }
      return false
    })

    this.rows = temp
    this.table.offset = 0
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event)
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row)
    this.table.rowDetail.toggleExpandRow(row)
  }
}
